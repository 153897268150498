import { Splide } from '@splidejs/splide';

// JavaScript code for the custom web component
class ZoomSlider extends HTMLElement {
  constructor() {
    super();

    this.triggers = document.querySelectorAll('.zoom-trigger');

    this.mediaSlider = new Splide(document.querySelector('product-media'), {
      height: '100%',
      type: 'loop',
      pagination: true,
      arrows: true,
      perPage: 1,
      arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
      heightRatio: 1.285,
      classes: {
        arrows: 'splide__arrows product-media-arrows',
        arrow: 'splide__arrow product-media-arrow opacity-1',
        prev: 'splide__arrow--prev',
        next: 'splide__arrow--next',
        pagination: 'splide__pagination media-slider__pagination lg:hidden relative', // container
        page: 'media-slider__page' // each button
      }
    });

    this.slider = new Splide(this, {
      height: '100%',
      type: 'loop',
      isNavigation: true,
      pagination: false,
      arrows: true,
      lazyLoad: 'nearby',
      perPage: 1,
      drag: true,
      arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
      classes: {
        arrows: 'splide__arrows product-media-arrows absolute w-full top-1/2',
        arrow: 'splide__arrow product-media-arrow opacity-1',
        prev: 'splide__arrow--prev',
        next: 'splide__arrow--next',
        pagination: 'splide__pagination media-slider__pagination lg:hidden relative', // container
        page: 'media-slider__page' // each button
      }
    });
  }

  connectedCallback() {
    this.initSlider();
  }

  triggerCallback() {
    this.classList.toggle('max-h-0');
    document.body.classList.toggle('overflow-hidden');
  }

  initSlider() {
    this.mediaSlider.sync(this.slider);
    this.mediaSlider.mount();
    this.slider.mount();

    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.triggerCallback.bind(this));
    });

    if (this.slider.state.is(Splide.STATES.IDLE)) {
      this.classList.add('max-h-0');
    }

    this.initZoom();
  }

  initZoom() {
    const images = this.querySelectorAll('.splide__slide img');
    const container = this;
    let startTouchX = 0;
    let startTouchY = 0;
    const TAP_THRESHOLD = 10; // Adjust as needed to define what counts as a "tap"

    images.forEach((image) => {
      let scale = 1;
      let translateX = 0;
      let translateY = 0;
      let maxTranslateX = 0;
      let maxTranslateY = 0;
      let isPanning = false;
      let isDragging = false;
      let startX = 0;
      let startY = 0;

      // Handle tap to zoom/unzoom
      image.addEventListener('click', handleTap.bind(this));
      image.addEventListener('touchend', handleTap.bind(this));

      function handleTap(event) {
        let clientX, clientY;
        if (event.type === 'touchend') {
          clientX = event.changedTouches[0].clientX;
          clientY = event.changedTouches[0].clientY;

          // Check if it's a tap (by comparing touchstart and touchend positions)
          const diffX = Math.abs(clientX - startTouchX);
          const diffY = Math.abs(clientY - startTouchY);
          if (diffX > TAP_THRESHOLD || diffY > TAP_THRESHOLD) {
            return; // Not a tap, don't unzoom
          }
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        if (!isDragging) {
          if (scale === 1) {
            this.slider.options = {
              drag: false,
              arrows: false
            };
            scale = 4; // Set your zoom-in value here

            const containerRect = container.getBoundingClientRect();
            const imageRect = image.getBoundingClientRect();

            let relativeX = clientX - imageRect.left;
            let relativeY = clientY - imageRect.top;

            const centerX = imageRect.width / 2;
            const centerY = imageRect.height / 2;

            const offsetX = relativeX - centerX;
            const offsetY = relativeY - centerY;

            maxTranslateX = Math.max(0, (imageRect.width * scale - containerRect.width) / scale / 2);
            maxTranslateY = Math.max(0, (imageRect.height * scale - containerRect.height) / scale / 2);

            translateX = -offsetX / scale;
            translateY = -offsetY / scale;

            if (translateX > maxTranslateX) {
              translateX = maxTranslateX;
            } else if (translateX < -maxTranslateX) {
              translateX = -maxTranslateX;
            }

            if (translateY > maxTranslateY) {
              translateY = maxTranslateY;
            } else if (translateY < -maxTranslateY) {
              translateY = -maxTranslateY;
            }

            image.style.transformOrigin = `center center`;
            image.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
          } else {
            scale = 1;
            this.slider.options = {
              drag: true,
              arrows: true
            };
            image.style.transformOrigin = `center center`;
            image.style.transform = `scale(${scale}) translate(0px, 0px)`;
          }
        }
      }

      // Handle touch start to record the initial touch position
      image.addEventListener('mousedown', handleStart);
      image.addEventListener('touchstart', handleStart);

      function handleStart(event) {
        if (scale > 1) {
          isPanning = true;
          isDragging = false;

          let clientX, clientY;
          if (event.type === 'touchstart') {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
          } else {
            clientX = event.clientX;
            clientY = event.clientY;
          }

          startTouchX = clientX;
          startTouchY = clientY;

          startX = clientX - translateX * scale;
          startY = clientY - translateY * scale;
          image.style.cursor = 'grabbing';
          event.preventDefault();
        }
      }

      image.addEventListener('mousemove', handleMove);
      image.addEventListener('touchmove', handleMove);

      function handleMove(event) {
        if (!isPanning) return;
        isDragging = true;

        let clientX, clientY;
        if (event.type === 'touchmove') {
          clientX = event.touches[0].clientX;
          clientY = event.touches[0].clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        let newTranslateX = (clientX - startX) / scale;
        let newTranslateY = (clientY - startY) / scale;

        // Clamping based on the new max values
        if (newTranslateX > maxTranslateX) {
          newTranslateX = maxTranslateX + (newTranslateX - maxTranslateX) * 0.3;
        } else if (newTranslateX < -maxTranslateX) {
          newTranslateX = -maxTranslateX + (newTranslateX + maxTranslateX) * 0.3;
        }

        if (newTranslateY > maxTranslateY) {
          newTranslateY = maxTranslateY + (newTranslateY - maxTranslateY) * 0.3;
        } else if (newTranslateY < -maxTranslateY) {
          newTranslateY = -maxTranslateY + (newTranslateY + maxTranslateY) * 0.3;
        }

        translateX = newTranslateX;
        translateY = newTranslateY;

        image.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
      }

      image.addEventListener('mouseup', handleEnd);
      image.addEventListener('touchend', handleEnd);

      image.addEventListener('mouseleave', handleEndNoSnap);

      function handleEnd() {
        isPanning = false;
        image.style.cursor = 'grab';
        if (scale !== 1) snapBackToBounds(image, translateX, translateY, maxTranslateX, maxTranslateY, scale);
      }

      function handleEndNoSnap() {
        isPanning = false;
        image.style.cursor = 'grab';
      }

      // Helper function to snap back to bounds after dragging
      function snapBackToBounds(image, translateX, translateY, maxTranslateX, maxTranslateY, scale) {
        let clampedTranslateX = Math.max(-maxTranslateX, Math.min(translateX, maxTranslateX));
        let clampedTranslateY = Math.max(-maxTranslateY, Math.min(translateY, maxTranslateY));

        image.style.transition = 'all 0.3s ease-out';
        image.style.transform = `scale(${scale}) translate(${clampedTranslateX}px, ${clampedTranslateY}px)`;

        setTimeout(() => {
          image.style.transition = '';
        }, 300);
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', (event) => {
  customElements.define('product-zoom', ZoomSlider);
});
